$().ready(function(){
	setupSlideMenu();
	$("form").addClass("floatlabel-active").find(".js-float-label-wrapper").FloatLabel();
	$(".infield").inFieldLabels();
});

export function setupSlideMenu(){

	$("#mobile-menu, .slide-menu h3").click(function(){
		$("body").toggleClass("slide-menu-active");
	});

	$(".slide-menu").on("click",".expand",function(e) {
		e.stopPropagation();
		$(this).closest("li").toggleClass("expand-active");
	});

	$(".slide-menu li").each(function(index, element) {
		var $el = $(element);
		if ($el.find("ul").length > 0) {
			var expander = $("<span class=\"expand\"><i class=\"nav-arrow\"></i></span>");
			$el.append(expander);
			expander.click(function(e) {
				e.stopPropagation();
				$el.toggleClass("expand-active");
			});
		}
	});
}